import React from 'react';
import { connect } from 'react-redux';
import LayoutExam from 'layouts/LayoutExam';

class Home extends React.PureComponent {
    render() {
        return (
            <LayoutExam />
        );
    }
}

function mapStateToProps() {
    return {};
}
export default connect(mapStateToProps)(Home);